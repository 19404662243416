@import './src/styles/global.scss';

.customModalTitle{
  @include DesktopH3;
  text-align: center;
}
.customModalDescription{
  @include DesktopPlain-Text;
  text-align: center;
  margin-top: 20px;
}
.customModalFooter {
  width: 100%;
  padding-top: 32px;

  .btnPrimary {
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    background-color: $accent-main;
    color: $content-text-text-inverted;

    @include tablet {
      margin: 0;
      max-width: 100%;
    }
    &:hover{
      background-color: $accent-main-hovered;
    }
  }
}
