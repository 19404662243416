@import './src/styles/global.scss';

.messageWrap{
  display: flex;
  color: $content-text-main;
  margin-bottom: $spacing-xs;
  .avatar{
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: $spacing-xs;
    border-radius: 50%;
    background: url("../../images/ic_empty_avatar.svg") center no-repeat;
    background-size: cover;
    >div{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
    }
  }
  .message{
    flex-grow: 1;
    .name{
      @include DesktopButton;
      margin-bottom: $spacing-xs;
    }
    .text{
      padding: $spacing-xs;
      border: 1px solid $background-grey;
      border-radius: 0 $spacing-m $spacing-m $spacing-m;
      @include DesktopPlain-Text;
      &.join{
        color: $error-green;
      }
      &.leave{
        color: $error-error;
      }
    }
  }
}