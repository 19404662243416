@import './src/styles/global.scss';

.videoPlayer{
  background-color: $content-text-main;
  border-radius: 16px 16px 0 0;
  padding-top: 93%;
  position: relative;
  @include tablet {
    border-radius: 0;
    max-width: 450px;
    height: 450px!important;
    padding-top: 0;
    margin: 0 auto;
  }
  @include mobile {
    padding-top: 75%;
    height: auto!important;
  }
  >div{
    background-color: $content-text-main;
    border-radius: 16px 16px 0 0;
    position: absolute!important;
    top: 0;
    left: 0;
    @include tablet {
      border-radius: 0;
    }
  }
}
