$content-text-disabled: #bbb;
$content-text-text-inverted: #fff;
$accent-main-hovered: #4280c9;
$content-text-secondary: #7e7e7e;
$content-text-main: #36353a;
$accent-main: #54a3ff;
$accent-online: #98bf40;
$accent-promo: #fc3248;
$accent-promo-hovered: #c85f5c;
$error-green: #2ca87b;
$error-orange: #f49a33;
$accent-main-light: #f5f5f5;
$error-error: #ff7070;
$error-error-light: #fff4f4;
$dividers-default: rgba(66, 128, 201, 0.08);
$background-grey: #f5f5f5;
$background-blue: #eaf3f9;
$background-white: #fff;
$background-chat-background: #f6f9fd;
$accent-notifications: #d64971;

$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 20px;
$spacing-xl: 24px;
$spacing-xxl-2: 32px;
$spacing-xxl-8: 40px;
$spacing-xxl-6: 48px;
$spacing-xxl-9: 56px;

$tablet-start: 768px;
$mobile-start: 450px;
$resize-start: 1175px;

@mixin tablet {
  @media (max-width: #{$tablet-start}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$mobile-start}) {
    @content;
  }
}
@mixin resizeStart {
  @media (max-width: #{$resize-start}) {
    @content;
  }
}
