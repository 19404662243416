@import './src/styles/global.scss';

.call{
  width: 100%;
  max-width: 613px;
  border-radius: 16px;
  @include tablet {
    border-radius: 0;
    max-width: 100%;
  }
  .playerContainer{
    position: relative;
    .callForm{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 59px;
      background-color: #E5EBF1;
      border-radius: 0 0 16px 16px;
      @include tablet {
        border-radius: 0;
        height: 52px;
      }

      .btn{
        min-width: 144px;
        height: 43px;
        background-color: #fff;
        border: 1px solid #02B99B;
        color: #02B99B;

        @include tablet {
          height: 36px;
          line-height: 1.18;
        }
        &:hover{
          background-color: darken(#fff, 5%);
        }
        &.temp{
          top: 0; left: 0;
          position: absolute;
          height: 23px;
          border: 1px solid black;
          color: black;
        }
        &.finish{
          border: 1px solid $accent-promo;
          color: $accent-promo;
        }
      }
    }
    .localPlayerWrapper{
      border-radius: 16px 16px 0 0;
    }
  }
}
