@import './src/styles/global.scss';

.header{
  background-color: #fff;
  height: 63px;
  padding: 7px 0 8px;

  @include tablet {
    height: 52px;
    padding: 8px 16px;
  }
  .container{
    width: 100%;
    max-width: 1169px;
    margin: 0 auto;

    display: flex;
    justify-content: flex-end;

    @include tablet {
      justify-content: space-between;
    }
  }

  .counter {
    display: none;
    @include tablet {
      display: block;
    }
    .count {
      display: flex;
      align-items: center;
      margin-top: 6px;
      &:before{
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        margin-right: $spacing-xs;
        background: url("/images/ic_eye.svg") center no-repeat;
        background-size: contain;
      }
    }
  }
  .btnLogout{
    width: 145px;
    @include tablet {
      height: 36px;
    }
    &::before{
      content: '';
      display: block;
      margin-right: $spacing-xs;
      width: 24px;
      height: 24px;
      background: url("../../images/ic_logout.svg") center no-repeat;
    }
    &:hover{
      &:before{
        background: url("../../images/ic_logout_blue.svg") center no-repeat;
      }
    }
  }
}
