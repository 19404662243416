@import './src/styles/global.scss';

.spinLoader {
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
  &.fixed {
    position: fixed
  }
  &.active {
    display: block
  }

  .textLoader {
    width: 100px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    span {
      color: $content-text-main;
      font-size: 14px
    }
    .ldsEllipsis {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 49px;
      div {
        position: absolute;
        top: 18px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: $accent-main;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
        &:nth-child(1) {
          left: 0;
          animation: ldsEllipsis1 .6s infinite
        }
        &:nth-child(2) {
          left: 0;
          animation: ldsEllipsis2 .6s infinite
        }
        &:nth-child(3) {
          left: 20px;
          animation: ldsEllipsis2 .6s infinite
        }
        &:nth-child(4) {
          left: 39px;
          animation: ldsEllipsis3 .6s infinite
        }
      }
    }
  }
}

@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0)
  }
  100% {
    transform: scale(1)
  }
}

@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1)
  }
  100% {
    transform: scale(0)
  }
}

@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0)
  }
  100% {
    transform: translate(19px, 0)
  }
}