@import "variables";
@font-face {
  font-family: Roboto;
  src: local(Roboto-Regular),
  url('../fonts/roboto-regular/roboto-regular-webfont.eot'),
  url(../fonts/roboto-regular/roboto-regular-webfont.woff2) format("woff2"),
  url(../fonts/roboto-regular/roboto-regular-webfont.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: Roboto;
  src: local(Roboto-Medium),
  url('../fonts/roboto-medium/roboto-medium-webfont.eot'),
  url(../fonts/roboto-medium/roboto-medium-webfont.woff2) format("woff2"),
  url(../fonts/roboto-medium/roboto-medium-webfont.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@mixin DesktopH1() {
  font-family: "Roboto";
  font-size: 56px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
@mixin DesktopH2() {
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}
@mixin Mobile-H1() {
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
}
@mixin DesktopSubtitle() {
  font-family: "Roboto";
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
}
@mixin DesktopH3() {
  font-family: "Roboto";
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
}
@mixin MobileH2() {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
@mixin MobileH3() {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
@mixin DesktopH4() {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
@mixin MobileSubtitle() {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
@mixin MobileButton() {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
}
@mixin Mobile-H4() {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
@mixin DesktopButton() {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
}
@mixin MobilePlain-Text() {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}
@mixin DesktopPlain-Text() {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
}
@mixin DesktopCaption() {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}
@mixin MobileCaption() {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.btn{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: $accent-main-light;
  color: $content-text-main;
  height: 48px;
  @include DesktopButton;

  @include tablet {
    height: 46px;
  }
  &:hover{
    color: $accent-main-hovered;
  }
}

#sound{
  display: none;
}

