@import './src/styles/global.scss';

.chat{
  width: 100%;
  max-width: 541px;
  max-height: 630px;
  padding: 0 $spacing-xxs $spacing-xxs;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: $spacing-m $spacing-m 0 0;

  @include tablet {
    max-width: 100%;
    border-radius: 0;
  }
  .title{
    @include DesktopButton;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-xs $spacing-s;
    @include tablet {
      display: none;
    }
    .count{
      display: flex;
      align-items: center;
      &:before{
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        margin-right: $spacing-xs;
        background: url("/images/ic_eye.svg") center no-repeat;
        background-size: contain;
      }
    }
  }
  .chatWrapper{
    flex-grow: 1;
    border: 1px solid rgba(66, 128, 201, 0.08);
    border-radius: 14px;
    height: 100%;
    overflow-y: auto;
    padding: $spacing-xs;
    @include tablet {
      margin: 12px 4px;
    }
    .emptyChat{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      @include DesktopPlain-Text;
      color: $content-text-secondary;
    }
  }
}
