@import-normalize;
@import 'src/styles/global';

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  font-family: "Roboto", sans-serif;
  line-height: 1;
  &:global(.hide-scroll){
    overflow: hidden;
  }
  >div{
    height: 100%;
  }
}
html, body{
  height: 100%;
  *{
    box-sizing: border-box;
  }
}
ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote {
  &:before, &:after {
    content: '';
    content: none; } }

q {
  &:before, &:after {
    content: '';
    content: none; } }

table {
  border-collapse: collapse;
  border-spacing: 0; }
button {
  background-color: transparent;
  outline: none;
  border: 0;
  cursor: pointer; }
button, input, select, textarea { background-color: transparent; outline: none; border: 0; }

.App{
  display: flex;
  flex-direction: column;
  height: 100%;
  .content{
    background-color: $accent-main-light;
    flex-grow: 1;
    padding: 80px 0 20px;

    @include tablet {
      padding: 0;
    }
  }
  .container{
    width: 100%;
    max-width: 1169px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @include resizeStart {
      padding: 0 10px;
    }
    
    @include tablet {
      display: block;
      padding: 0;
    }
  }
}
