@import './src/styles/global.scss';

.customModalTitle{
  @include DesktopH3;
  text-align: center;
}

.customModalFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 32px;

  @include tablet {
    display: block;
  }
  .btnPrimary {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    background-color: $accent-main;
    color: $content-text-text-inverted;
    &:hover{
      background-color: $accent-main-hovered;
    }
    @include tablet {
      width: 100%;
      margin: 0;
      max-width: 100%;
    }
  }
  .btnDefault {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    background-color: $background-grey;
    color: $content-text-main;
    @include tablet {
      width: 100%;
      margin: 16px 0 0 0;
      max-width: 100%;
    }
    &:hover{
      color: $accent-main-hovered;
    }
  }
}
