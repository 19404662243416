@import './src/styles/global.scss';

.customModal{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  padding: 0 $spacing-xl;
  overflow-x: hidden;
  overflow-y: auto;

  .fadeModal {
    background-color: #000;
    opacity: 0.4;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .customModal {
    &Body {
      border-radius: $spacing-m;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 480px;
      height: auto;
      padding: $spacing-xxl-2;
      background-color: $background-white;
      -webkit-box-shadow: 0 5px 31px rgba(72, 73, 74, 0.07);
      -moz-box-shadow: 0 5px 31px rgba(72, 73, 74, 0.07);
      box-shadow: 0 5px 31px rgba(72, 73, 74, 0.07);
      position: relative;
      top: 50%;
      left: 50%;

      @include tablet {
        padding: 49px 16px 16px 16px;
      }

      .closeModal {
        width: 16px;
        height: 16px;
        position: absolute;
        background: url("../../images/ic_close_mod.svg") center no-repeat;
        top: 20px;
        right: 20px;
        cursor: pointer;
        opacity: 0.6;
        &:hover{
          opacity: 1;
        }
      }
    }

    &Title {
      @include DesktopH3;
      text-align: center;
      @include tablet {
        @include Mobile-H4;
      }
    }

    &Description {
      @include DesktopPlain-Text;
    }

  }

  &.fixedTop {
    .customModalBody {
      transform: translate(-50%, 0);
      top: 24px;
    }
  }
}
